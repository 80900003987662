.faq {
  cursor: pointer;
  height: 44px;
  display: flex;
  justify-content: space-between;
  @extend %entity;
  @extend %shadow;
  @include entity-layout(#{&});
  @include overlay(hover);
}

.faq-preview {
  position: relative;
  padding: 16px;
  border-radius: $radius;
}

.faq-preview:nth-child(odd) {
  background-color: #eee;
}

.faq-preview__title {
  font-size: 1.1em;
  font-weight: 500;
  line-height: 2;
}

.faq-preview__short-answer {
  color: $red;
  font-weight: 500;
  padding: 0 6px;
}

.faq-preview__buttons {
  position: absolute;
  top: 10px;
  right: 10px;
}

.faq-preview__button {
  display: inline;
  padding: 0 4px;

  & > i {
    color: rgba($gray, 0.5) !important;
    cursor: pointer;
    @extend %transition;
  }

  & > i:hover {
    color: rgba($gray, 1) !important;
  }
}
