.ruleset {

}

.ruleset__container {
    margin: 0 auto;
}

.settings__container {
    max-width: 1440px;
    margin: 0 auto;
}

.settings__item {
    margin-top: 10px;
}

.ruleset__list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ruleset__list-tag {
  margin: 0 8px;
}

.ruleset__filter {
  margin-bottom: 7px;
}

.ruleset__cover-img {
	width: 200px;
	height: 200px;
	background-color: #ccc;
}
