.table {
  width: 100%;

  a {
    color: $green;
    text-decoration: underline;
  
    &:hover {
      text-decoration: inherit;
    }
  }

  thead {
    background-color: $green;
    color: #fff;
    font-weight: 600;
  }

  td {
    padding: 10px 20px;
  }

  tr:nth-child(even) {
    background-color: rgba($gray, .25);
  }
}