.textarea {
  min-height: 100px;
}

.textarea_rule_description {
  color: $green;
  background-color: $blue-light;
  border: 1px solid $blue;
  outline: none;
  margin-top: 15px ;
  min-height: 60px;
}

.textarea_menu_description {
  color: $green;
  background-color: $blue-light;
  border: 1px solid $blue;
  outline: none;
  height: 60px;
  margin-top: 15px ;
}
