.child-tag {
  text-align: center;
  display: block;
  background: $gray-dim;
  padding: 1px;
  border-radius: 4px;
  width: 26px;
  height: 26px;
  margin: 1px 7px 0 0;
}

.child-tag-text {
  font-weight: 600;
  color: white;
  font-size: 12px;
}
