.subheader {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  font-weight: 600;
  background-color: $green-light;
}

.subheader__icon {
  padding-right: 8px;
  // Have to use flex with align because icons plugin has div wrap
  // Need to get other plugin or do it differently
  display: flex;
  align-items: center;
}

.subheader__icon img {
  width: 22px;
  margin-right: 2px;
}

.subheader__title {
  color: $green;
  font-weight: 700;
  padding-right: 10px;
}
