select.dropdown {
  border: none;
  font-size: 1em;
  height: 2em;
  padding: 5px;
  width: max-content;
  color: #fff;
  outline-color: $green;
  background-color: $green;
  border-radius: 5px;

  &:disabled {
    opacity: .35;
    appearance: none;
    color: #000;
    outline-color: $gray;
    background-color: $gray;
  }
}
