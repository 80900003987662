.hover-menu {
  padding: 8px 7px 6px;
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: #222;
  border-radius: 4px;
  transition: opacity 0.75s;
}

.hover-menu .hover-menu-button {
  color: #aaa;
  padding: 0 5px;
  cursor: pointer;
}

.hover-menu .hover-menu-button[data-active='true'] {
  color: #fff;
}

.widget-text {

  &.widget-text-empty {
    background-color: rgba(lightgrey, 0.2);
    padding: 4px;
    border-radius: 4px;
  }
}

span.spoiler {
  background-color: rgba(128, 128, 128, 0.5);
}
