// Decorative
%shadow {
  box-shadow: inset 0px -3px 0px 0px $gray30;
}

%transition {
  transition: all $transition-mode;
}

%overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $overlay-light;
  opacity: 0;
  transition: all $transition-mode;
  z-index: -1;
}

// Wrappers
%wrap {
  padding: 0 24px;
  margin: 0px auto;
}

// Entities
%entity {
  color: #fff;
  font-weight: 600;
  position: relative;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: $radius;
  z-index: 1;
  max-height: 44px;
  min-height: 44px;

  & > input {
    color: #fff;
    background-color: $overlay-dark;
    font-weight: 600;
  }
}
