.modal__header {
  font-size: 18px;
  color: $red;
  font-weight: 600;
  padding: 16px 0 0 0;
}

.modal__text {
  padding: 12px 0;
  font-weight: 500;

  p {
	line-height: 1.5em;
  }
}

.modal__wrapper {
  position: relative;
  width: 80%;
  text-align: center;
  .modal__button {
	min-height: 40px;
	max-width: 30%;
	padding-top: 10px;

	&:first-child {
	  float: left;
	  clear: left;
	}
	&:last-child {
	  float: right;
	  clear: right;
	}

	&:only-child {
	  transform: translate(-120%);
	}
  }
}

.modal__wrapper__secondary {
  position: relative;
  text-align: center;
  .modal__button {
	min-height: 50px;
	padding-top: 10px;
	margin: auto;
  }
}

.modal__button > * {
  width: 100%;
  margin: 4px 0;
}

.modal__form > * {
  margin: 4px 0;
}
