.rule-previewer {
  height: 0;
  overflow: hidden;
  @extend %transition;

  & > div {
    padding: 0 8px;
    margin: 16px 0;
  }

  &_show {
    height: 100%;
  }

  &__subheader {
    font-size: 18px;
    font-weight: 600;
    margin: 16px 0;
  }

  &__question {
    margin-bottom: 24px;
  }

  &__question-number {
    border-radius: 3px;
    margin-right: 8px;
    padding: 8px;
    background-color: $blue-light;
  }

  &__title {
    font-weight: 500;
  }

  &__answer {
    margin-left: 4px;
    color: $red;
  }

  &__text {
    padding-top: 12px;
  }

  & iframe {
    border: 1px dashed $blue;
    border-radius: $radius;
  }

  &__related-rule:not(:last-child) {
    padding-bottom: 8px;
  }
}