.rule-builder__note {
  border-radius: $radius;
  background: $blue-light2;
  padding: $gapXL;
  font-weight: 600;
  font-style: italic;
  font-size: 1.5em;
  line-height: 1.25;
}

.note__header {
  color: $blue-strong;
  font-size: 1em;
  line-height: 2;
}