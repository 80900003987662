.input {
  width: 100%;
  padding: 8px;
  transition: background $transition-mode,
  border $transition-mode;
}

.input_term {
  position: absolute;
  color: gray;
  background-color: $blue-light;
  border: 1px solid $blue;
  outline: none;
  height: 40px;
  padding: 5px;
  font-weight: 700;
}

.input_filter {
  color: $green;
  background-color: $blue-light;
  border: 1px solid $blue;
  outline: none;
}

.input_auto-size {
  border: 1px dashed $blue;
  padding: 8px;
  background-color: transparent;
  &:focus {
	background-color: rgba($blue, .3);
  }
}

.input_warning {
  border: 1px dashed $red;
}
