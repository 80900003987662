// Colors
$green: #0090a1;
$uncategorized: #9e9e9e;
$green-dark: #00434b;
$green-light: #bbdce0;

$blue: #72ccd2;
$blue-strong: #319bc3;
$blue-light: #e3f3f4;
$blue-light2: #dbf6ff;


$gray: #a0a2a4;
$gray30: rgba(80, 90, 80, 0.18);
$gray-light: #e9eff0;
$gray-dim: rgba(80, 90, 80, 0.40);

$red: #f50057;
$black: #202020;
$orange: #ff9900;
$darkorange: #FD8527;
$orangeshadow : #D57020;

// Fonts
$font: Raleway;
$font-stack:  $font,
  -apple-system,
  BlinkMacSystemFont,
  Segoe UI, Roboto,
  Oxygen, Ubuntu,
  Cantarell, Fira
  Sans, Droid Sans,
  Helvetica Neue,
  sans-serif;

// Border
$radius: 3px;

// Effects
$transition-type: ease-in-out;
$transition-mode: .15s $transition-type;
$overlay-light: rgba(#fff, .15);
$overlay-dark: rgba(#000, .15);

// Grid
$gapXL: 16px;
$gapL: 10px;

// Sizes
$content_w: 2.5fr;
$sidebar_w: 380px;
