@import '../../styles/_variables.scss';

.rule-overview {
	.info-string {
		margin-bottom: 10px;
	}
}

.rule-overview__header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $gapXL;
}

.rule-overview__information {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rule-overview__information-header {
  text-transform: uppercase;
  font-weight: 600;
  color: $gray;
  margin: 0 8px;
}

.rule-overview__description {
  z-index: 100;
}

.rule__related-rules {
  margin: 32px 0;
}
