.tag {
  display: inline-flex;
  font-size: .8em;
  background-color: $orange;
  color: #fff;
  font-weight: 600;
  position: relative;
  align-items: center;
  padding: 2px 8px;
  border-radius: $radius;
  z-index: 1;
}

.tag__icon {
  padding-right: .3em;
  cursor: pointer;
}
