@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,600,700,900');

html {
  box-sizing: border-box;
  height: 100%;
  overflow-y: scroll;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  background: $gray-light;
  color: $black;
  font-family: $font-stack;
  min-height: 100%;
  line-height: 1.5em;
}

a,
a:hover,
a:active {
  text-decoration: none;
}

input,
textarea {
  width: 100%;
  font-family: $font-stack;
  border-radius: $radius;
  border: none;
  background-color: #fff;
  outline: none;
}

textarea {
  resize: none;
}

em {
  font-style: italic;
}

@import '_typography';

.wrap1140 {
  @extend %wrap;
  max-width: 1440px;
}

.wrap960 {
  @extend %wrap;
  max-width: 960px;
}

