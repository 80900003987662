.info-string {
    color: $gray;
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
    margin: 0px auto;
    max-width: 1440px;
}

.info-string__container {
    background: #D1E6E8;
}

.info-string__buttons {
    /* Keep in one row */
    display: flex;
    /* Keep right align and ignore line-height */
    justify-content: flex-end;
    align-items: center;
}

.info-string__buttons > *:not(:first-child) {
    margin-left: 8px;
}

.info-string__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    font: {
        size: .8em;
        weight: 600;
    }
    line-height: 1.2em;
}

.info-string__li {
    padding: 0 12px;
}
