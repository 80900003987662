.grid {
  display: grid;
  grid-gap: $gapXL;
}

.grid_2cols {
  grid-template: {
    columns: $sidebar_w $content_w;
    areas: "sidebar main";
  }
}

.grid__main {
  grid-area: main;
}

.grid__sidebar {
  grid-area: sidebar;
}

.grid-list {
  display: grid;
  grid-gap: 3px;
}

.grid_gap_L {
  grid-gap: $gapL;
}

.grid_gap_XL {
  grid-gap: $gapXL;
}
