@import '../../styles/_variables.scss';

.faq-editor {
  max-width: 1200px;
  margin: auto;
}

.faq-editor-overview {
	.info-string {
		margin-bottom: 10px;
	}
}

.faq-editor-bg {
  height: 40px;
  background: $gray;
}

.faq-editor-menu-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  height: 40px;
}

.faq-editor-menu-shadow {
  background: $orangeshadow;
  height: 3px;
}

.faq-editor-menu-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
  color: white;
  font-weight: 600;
  font-size: 14px;
}

.faq-editor-menu-buttons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 30px;
  color: $gray;
  font-weight: 600;
  text-transform: uppercase;
}

.faq-editor-menu-buttons > *:not(:first-child) {
  margin-left: 8px;
}

.faq-editor-content {
  margin-top : 20px;
}

.faq-editor__grid {
  display: grid;
  grid-template-columns: 980px 200px;
  grid-gap: 10px 20px;
}

.faq-editor__title {
  font-weight: 400;
}

.faq-editor__text {
  flex-basis: 40%;
  font-weight: 700;
  font-size: 14px;
  color: gray;
}

.faq-editor-related {
  margin-top: 20px;
  margin-bottom: 10px;
}

.faq-editor-related > *:not(:first-child) {
  margin-top: 10px;
}

.faq-editor-body {
  margin-top: 15px;
}

.faq-editor-answer {
  color: black;
  margin-top: 10px;
  padding: 12px;
  height: 120px;
}

.faq-editor-input-question {
  color: gray;
  font-weight: 700;
  height: 30px;
  padding-left: 12px;
}

.faq-editor-input-short {
  color: gray;
  font-weight: 600;
  font-size: 14px;
  height: 30px;
  padding-left: 12px;
}
