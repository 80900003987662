@import '../../styles/_variables.scss';

.faqs {
  max-width: 960px;
}

.faqs_title {
  margin: 5px 10px 0 0;}

.faqs_options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.faqs_options_buttons {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;
}

.faqs_tag {
  margin-right: 5px;

  > * {
    color: $blue-strong;
  }
}

.faqs_tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 2;
}

.faqs_filter_remove {

}

.faqs_filter_search {
  position: fixed;
  right: 690px;
  margin-bottom: 10px;
}

.faqs_filter {
  position: relative;
  height: 40px;
  margin-bottom: 10px;
}

