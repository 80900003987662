.tab_container {
    background: #D1E6E8;
}

.tab_list {
    height: 38px;
    max-width: 1440px;
    margin: 0px auto;
}

.tab_item {
    display: inline-block;
    list-style: none;
    height: 33px;
    min-width: 120px;
    margin: 0 4px 3px 0;
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
    background: #BBDCE0;
    text-align: center;
    color: #0090A1;
    text-transform: uppercase;
    line-height: 1.3em;
    outline: none;
    font-family: Raleway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: 800;
}

.tab_active {
    border-width: 1px 1px 0 1px;
    height: 100%;
    border-radius: 5px 5px 0 0;
    background: #E9EFF0;
    color: black;
}

.tab_disabled {
    color: #a0a2a4;
}


.tab_content {
    position: relative;
}
