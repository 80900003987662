.color-picker {
  padding: 8px;
}

.color-picker__list {
  display: flex;
  flex-wrap: wrap;
}

.color-picker__list li {
  height: 20px;
  width: 20px;
  margin: 2px;
  border-radius: $radius;
  cursor: pointer;
}
