.widget {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: $radius;
  background-color: $green-light;
  cursor: pointer;
  @include overlay(hover);

  &_reversed {
    transform: scaleX(-1);
  }
}