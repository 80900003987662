.asseticon {
  display: inline-flex;
  color: white;
  background: $green;
  padding: 1px 1px 1px 0;

  border: {
	width: 0;
	radius: $radius;
  }
}
