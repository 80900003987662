@import '../../styles/variables';

.rr {
    background-color: #fff;
    border-radius: 3px;
    padding: 16px 24px;
}

.rr_bg_gray {
  background-color: #eee;
}

.rr__list > div:not(:first-child) {
  margin-top: 4px;
}

.rr__headers {
  display: flex;
  height: 35px;
}

.rr__header {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.rr__header_space-between {
  justify-content: space-between;
}

.rr__clear-filters {
  padding: 4px;
  margin: 0 8px;
  background-color: $blue-light;
  color: $green;
  font-size: 13px;
  cursor: pointer;
  border-radius: 3px;
  text-decoration: none;
}

.rr__clear-filters:hover {
  text-decoration: underline;
}

.rr__icon {
  display: flex;
  align-items: center;
  margin-right: 6px;
}

.rr__icon_closed {
  margin: 0 0 0 6px;
  background-color: rgba(204, 204, 204, .8);
  padding: 0 6px;
  border-radius: 3px;
  cursor: pointer;
}

.rr__id {
  padding: 0 4px;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
}

.rr__rules {
  display: flex;
}

.rr__desc {
  font-size: 15px;
  padding: 0 8px;
}

.rr__rule-wrap {
  display: flex;
  justify-content: space-between;
  border-bottom: 3px solid rgba(80, 90, 80, .2);
  background-color: $green;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
}

.rr__rule-wrap:hover {
  opacity: .9;
}

.rr__rule-closed {
  display: flex;
  justify-content: space-between;
}

.rr__rule {
  height: 36px;
  padding: 0 8px;
  transform: translateY(1px);
  font-weight: 500;
  font-size: 14px;
  color: #fff;

  flex: 1;
  display: flex;
  align-items: center;
}

.rr__column {
  flex: 1;
  border-radius: 3px;
  max-height: 300px;
  margin-top: 12px;
}

.rr__column_left {
  max-height: 254px;
}

.rr__column:first-child {
  margin-right: 8px;
}

.rr__input > input {
  margin: 5px 0;
}

.rr__form > *:not(:first-child) {
  margin-top: 8px;
}

.rr__buttons {
  padding: 12px 0;
}

.rr__buttons > *:first-child {
  margin-right: 6px;
}