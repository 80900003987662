.faq-previewer {
  height: 0;
  overflow: hidden;
  @extend %transition;

  &_show {
    height: 100%;
  }

  &__container {
    margin: 20px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__title {
    flex-basis: 40%;
    font-weight: 700;
  }

  &__text {
    flex-basis: 40%;
    font-weight: 700;
    font-size: 14px;
    color: gray;
  }

  &__body {
    margin-top: 10px;
    margin-bottom: 10px;  }

  &__answer {
    margin-left: 4px;
    color: $red;
  }

  &__related {
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 70%;
  }

  &__related-rule:not(:last-child) {
    padding-bottom: 8px;
  }

  &__rule {
    height: 36px;
    padding: 0 8px;
    transform: translateY(1px);
    font-weight: 500;
    font-size: 14px;
    color: #fff;

    flex: 1;
    display: flex;
    align-items: center;
  }

  &__rule_wrap {
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid rgba(80, 90, 80, .2);
    background-color: $green;
    border-radius: 7px;
    cursor: pointer;
    width: 100%;
  }

  &__rule_icon {
    display: flex;
    align-items: center;
    margin-right: 6px;
  }
}
