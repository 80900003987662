.button {
  color: #fff;
  cursor: pointer;
  background-color: $green;
  min-width: 10em;
  text-transform: uppercase;
  line-height: 1.8em;
  outline: none;
  font: {
    family: $font-stack;
    weight: 900;
  }
  border: {
    width: 0;
    radius: $radius;
  }
  @extend %shadow;
  @include overlay(active);
}

.button:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.button_secondary {
  background-color: $orange;
}

.button_close {
  background-color: $orange;
  min-width: 2em;
  line-height: 0;
  padding: 0;
}

.input_term_button {
  background-color: $orange;
  min-width: 2em;
  line-height: 0;
  right: 5px;
  margin-top: 5px;
  padding: 0;
  position: absolute;
}

.button_inactive {
  background-color: $gray;
}

