.widget_tab {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 .8em;
  border-radius: 3px 3px 0 0;
  cursor: pointer;
  transition: $transition-mode;

    &_active {
      padding: 5px;
      background-color: #fff;
    }

    &:not(.widget_tab_active):hover {
      background-color: rgba($green, .2);
    }
}
