body {
  min-width: 1024px;
}

/* Re-write component style */
.Select-menu-outer {
	z-index: 2 !important;
}

.Select-value {
  border: none !important;
  color: #fff;
}

.Select-multi-value-wrapper {
  display: flex !important;
  align-items: center !important;
  height: 100%;

  & > * {
    margin-left: 4px;
  }
}

.Select-control {
  border: none !important;
  box-shadow: none !important;
  border-radius: 3px !important;
  height: 34px !important;
}
