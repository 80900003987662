@mixin overlay($onEvent) {
  position: relative;
  z-index: 1;

  &:#{$onEvent}:after {
    opacity: 1;
  }

  &:after {
    @extend %overlay;
  }
}

@mixin entity-layout($name) {
  #{$name}__title {
    flex: 1 auto;
    padding: 0 4px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #{$name}__buttons {
    display: flex;
    align-items: center;
    margin-top: -3px;
  }

  #{$name}__icon {
	display: flex;
	margin-right: 5px;
	max-height: 40px;
  }

  #{$name}__img {
	display: flex;
	max-height: 40px;
	max-width: 40px;
  }

  #{$name}__button {
    opacity: .5;
    padding: 0 4px;

    & > * {
      display: flex;
      align-items: center;
    }
  }
}
