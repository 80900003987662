.menu {
  cursor: pointer;
  @extend %entity;
  @extend %shadow;
  @include entity-layout(#{&});
  @include overlay(hover);
}

.menu_show-all {
  background-color: $green;
  text-transform: uppercase;
  font-weight: 900;
  padding-left: 10px;
}

.menu_uncategorized {
	background-color: $uncategorized;
	border: 1px dashed #fff;
	box-shadow: none;
}

.menu_colors_wrapper {
  float: left;
}
