h1, h2, h3, h4 {
  margin: 0.5em 0;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: 2.441em;
}

h2 {
  font-size: 1.953em;
}

h3 {
  font-size: 1.563em;
}

h4 {
  font-size: 1.25em;
}

p {
  margin: 1em 0;
}
