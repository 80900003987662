.rule-builder__youtube {
  display: flex;
  justify-content: center;

  .rule-builder__youtube_inputs {
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 1em;
    row-gap: 0.5em;
    padding: 0.5em;
    background-color: white;
    border-radius: 4px;
    position: absolute;
    top: 5px;
    left: 5px;
    visibility: hidden;
    opacity: 0;
    @extend %transition;

    .label {
      padding: 4px;
      align-self: center;
    }
  }

  &:hover,
  &:active {
    .rule-builder__youtube_inputs {
      visibility: visible;
      opacity: 1;
    }
  }

}
