@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/templates';

.rule-builder {
  grid-template-columns: $content_w $sidebar_w;
}

.rule-builder__drop-area {
  box-shadow: none;
  @extend %transition;
}

.rule_builder__drop-area_active {
  box-shadow: 0px 0px 15px 2px rgba($blue-strong, 0.3);
}

.rule-builder__line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  margin-bottom: $gapXL;
}

.rule-builder__content {
  background-color: #fff;
  border-radius: $radius;
  padding: $gapXL;
}

.rule-builder__content_empty {
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px dashed $green-light;
  border-radius: $radius;
  background-color: transparent;
  color: $green;
}

.rule-builder__row {
  position: relative;
  background-color: #fff;
  border-radius: $radius;
  padding: 15px;
  border: 2px dashed #f1f1f1;
  transition: background $transition-mode;
  p {
    margin: 0;
  }

  &:hover,
  &:active {
    @extend %transition;
    border: 1px dashed $blue;
    background-color: rgba($blue-light, 0.5);

    & > .rule-builder__handles > div {
      visibility: visible;
      opacity: 1;
    }
  }
}

/*
  Clearfix! Need to test.
  It's needed to fix height problem of the parent element.
  Cause: floats.
*/
.rule-builder__row:after {
    content: "";
    clear: both;
    display: table;
}

.rule-builder__handles {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;

  & > div {
    visibility: hidden;
    opacity: 0;
    @extend %transition;
  }
}

.rule-builder__delete {
  cursor: pointer;
}

.rule-builder__img_left {
  margin: 0 0 15px 0;
}

.rule-builder__img_right {
  margin: 15px 0 0 0;
}

.widget_tabs {
  height: 40px;
  display: flex;
  align-items: center;
  background-color: $green-light;
  border-radius: 3px 3px 0 0;
}

.widgets {
  padding: $gapXL;
  grid-template-columns: repeat(3, 1fr);
}

@import 'widgets/ImageWidget/styles';
@import 'widgets/NoteWidget/styles';
@import 'widgets/TextWidget/styles';
@import 'widgets/YouTubeWidget/styles';

.rule-builder__img_left {
  margin: 0 25px 0 0;
}

.rule-builder__img_right {
  margin: 0 0 0 25px;
}

.s-1 {
  float: left;
  width: 33.33%;
}

.s-2 {
  float: left;
  width: 66.66%;
}

